// 成员活跃度接口
import api from "@/base/utils/request";
import download from "@/base/utils/download"

// 获取成员排名列表
export const memberList = data => {
    return api({
      url: "/admin/circle/GroupInteract/interactRank",
      method: "post",
      data
    });
  };

// 下载导出
export const memberexport = data => {
  return download({
    url: "/admin/circle/GroupInteract/interactRankExport",
    method: "post",
    data,
    download: true
  });
};

// 获取所属架构/职务列表
export const positionLevel = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data
  });
};
  