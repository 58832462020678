<template>
  <LayoutFilter
    :onFilter="onFilter"
    :onReset="resetForm"
    :toggleCollapseEnable="false"
    :onExport="onExport"
  >
    <el-form :model="form"  label-width="80px" class="filter-from" ref="resetFilterForm">
      <div class="filter-item">
        <el-form-item label="用户名称：" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="会内职务：" prop="positionIds">
          <el-cascader v-model="form.positionIds" :options="parentPositionList" :props="props" placeholder="请选择"></el-cascader>
        </el-form-item>
      </div>
      <div class="filter-item" v-if="inactiveqh">
        <DatePeriodPicker label="时间段：" 
          start-prop="start_time"
          :start-time.sync="form.start_time" 
          end-prop="end_time"
          :end-time.sync="form.end_time" 
          :showBtn="false"
        ></DatePeriodPicker>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { memberexport, positionLevel } from '../../api/group/member-activity';
export default {
  props: {
    // tab切换
    inactiveqh: {
      type: Boolean,
      default: true
    },
    // 筛选
    filterChange: {
      type: Function,
      required: true
    },
    // 导出
    exportData: {
      type: Object,
      required: true
    },
    page: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      parentPositionList: [],
      // 联级选择配置
      props: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'position'
      },
      // form
      form: {
        nickname: '',
        title_name: '',
        start_time: -1,
        end_time: -1,
        positionIds: [], 
      }
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["resetFilterForm"].resetFields();
      return this.onFilter();
    },
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form))
    },
    // 导出
    onExport() {
      const postData = {...this.exportData, page: this.page}
      return memberexport(postData)
        .then()
        .catch((err) => {console.log(err)});
    },

  },
  computed: {
    position_id: {
      get() {
        return this.form.position_id == -1 ? "" : this.form.position_id;
      },
      set(val) {
        this.form.position_id = val
      }
    }
  },
  created () {
    positionLevel({ is_list_tree: 0, is_show_all: 1 })
    .then((res) => {
      this.parentPositionList = res.data
    })
    .catch(err => console.log(err))
  },
  watch: {
    positionIds(val) {
      this.position_id = val[val.length -1] ? val[val.length - 1] : -1
    }
  },
  components: {
    LayoutFilter,
    DatePeriodPicker
  },
};
</script>

<style lang="scss" scoped>

</style>
