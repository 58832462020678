<template>
<div>
<!-- 成员排名 -->
  <listLayout
    ref="ListLayout"
    v-show="thead[theadKey].length && form.tab === 'normal'"
    v-if="form.tab === 'normal'"
    :tabArr="tabArr"
    :on-fetch="getList"
    :filter-form="filterForm"
    :thead="thead[theadKey]"
    @handletab="handletab"
  >
    <template #filter>
      <activity-filter
        ref="activityFilter"
        :page="pageData.current_page"
        :filterChange="onFilter"
        :exportData="filterForm"
      ></activity-filter>
    </template>
  </listLayout>
<!-- 潜水成员 -->
  <listLayout
    ref="ListLayout"
    v-if="thead[theadKey].length && form.tab !== 'normal'"
    :tabArr="tabArr"
    :on-fetch="getList"
    :filter-form="filterForm"
    :thead="thead[theadKey]"
    @handletab="handletab"
  >
    <template #filter>
      <activity-filter
        ref="activityFilter"
        :inactiveqh="false"
        :page="pageData.current_page"
        :filterChange="onFilter"
        :exportData="filterForm"
      ></activity-filter>
    </template>
  </listLayout>
</div>

</template>
<script>
import activityFilter from "../../components/group/MemberActivityFilter";
import ListLayout from "@/base/layout/ListLayout";
import { memberList } from "../../api/group/member-activity";
export default {
  data() {
    return {
      // 加载
      loading: false,
      tbody: [],
      // tab配置
      tabArr: [
        { label: "成员排名", name: "normal" },
        { label: "潜水成员", name: "inactive" },
      ],
      //
      thead: {},
      // 页码
      pageData: {
        current_page: 1
      },
      tabs: {

      },
      // 筛选对象
      filterForm: {
        id: this.$route.params.id,
        tab: "normal",
        nickname: "",
        title_name: "",
        order_by: 'total_rank',
        start_time: -1,
        position_id: -1, // 职务id
        // positionIds: [], // 会内职务
        end_time: -1,
        page_size: 20,
      },
      // form
      form: {
        tab: 'normal'
      },
    };
  },

  methods: {
    getList(data) {
      return memberList(data)
    },
    // 筛选回调
    onFilter(filterObj) {
      Object.assign(this.filterForm, filterObj, { page: 1 });
      this.$refs.ListLayout.getList();
    },
    // 切换tab 更改order_by参数
    handletab(tab) {
      return this.filterForm.order_by = tab === 'normal' ? 'total_rank' : undefined
    }
  },
  created() {
    // 成员排名
    let thead = {
      normal: [
        {
          label: "综合排名",
          prop: "total_rank",
          sortable: true,
          sort_orders: ['ascending', 'descending'],
          minWidth: 150,
        },
        { label: "头像", prop: "avatar", minWidth: 100 },
        { label: "用户名称", prop: "nickname", minWidth: 150 },
        { label: "会员职务", prop: "title_name", minWidth: 100 },
        {
          label: "互动天数",
          prop: "date_interact_count",
          sortable: true,
          sort_orders: ['ascending', 'descending'],
          minWidth: 150,
        },
        {
          label: "互动天数排行名",
          prop: "date_rank",
          sortable: true,
          sort_orders: ['ascending', 'descending'],
          minWidth: 150,
        },
        {
          label: "互动次数",
          prop: "interact_count",
          sortable: true,
          sort_orders: ['ascending', 'descending'],
          minWidth: 150,
        },
        {
          label: "互动次数排行名",
          prop: "interact_count_rank",
          sortable: true,
          sort_orders: ['ascending', 'descending'],
          minWidth: 150,
        },
        {
          label: "互动内容量",
          prop: "interact_sum",
          sortable: true,
          sort_orders: ['ascending', 'descending'],
          minWidth: 200,
        },
        {
          label: "互动内容量排行名",
          prop: "interact_sum_rank",
          sortable: true,
          sort_orders: ['ascending', 'descending'],
          minWidth: 200,
        },
      ],
      inactive: [
        { label: "序号", type: "index", },
        { label: "头像", prop: "avatar", minWidth: 150, },
        { label: "用户名称", prop: "nickname", minWidth: 150, },
        { label: "会员职务", prop: "title_name", minWidth: 150, },
        { label: "连续潜水天数", prop: "inactive_count", sortable: true, sort_orders: ['ascending', 'descending'], },
      ],
    };
    this.thead = thead;
  },
  watch: {
  },
  computed: {
    // 获取tab
    theadKey() {
      return this.form.tab = this.filterForm.tab;
    },
  },
  components: {
    activityFilter,
    ListLayout,
  },
};
</script>
<style lang="scss" scoped>
.card {
  margin: 20px 0px;
  .el-tabs {
    .el-tab-pane {
      margin-top: 20px;
    }
  }
}
</style>
